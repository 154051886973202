
;(function ($, window, undefined) {

  var $window = $(window)

  var Common = Common || {
    DEBUG: false,
    Page: null,
    BREAK_POINT_TAB: 900,
    BREAK_POINT_SP: 767,
    Window: {
      $window: null,
      $document: null,
      width: 0,
      height: 0
    },
    LAYOUT_MODE: 'pc',
    current_layout_mode: 'pc',
    user_agent: null,
    is_mobile: false,
    is_android: false,
    is_windows: false,
    is_mac: false,
    is_ie: false,
    is_ie10: false,
    is_ie11: false,
    is_chrome: false,
    is_safari: false,
    is_opera: false,
    is_firefox: false,
    is_edge: false,
    loaded_img_len: 0,
    img_len: 0,
    img_src_ary: [],
    is_intro_play: false, // イントロが用意されているか？
    is_intro_skip: false, // イントロをスキップするか？
    $body: null,
    timeout_timer: null, // 画像プリロードのタイムアウト用タイマー
    timeout_limit: 3000, // タイムアウトの時間

    /* -----------------------------------------------
     * 初回に一度だけ実行する
     * ----------------------------------------------- */
    setupOnce: function () {
      Common.trace('func', 'setupOnce')

      Common.$body = $('body')
      Common.$body.addClass('is-overflow-hidden')
      window.scrollTo(0,0);

      // ウィンドウ関連の情報をセット
      Common.Window.$window = $(window)
      Common.Window.$document = $(document)
      Common.Window.width = Common.Window.$window.width()
      Common.Window.height = Common.Window.$window.height()

      // URLクエリを取得
      Common.URL_QUERY = Common.getURLQuery()

      // デバッグモード
      if(Common.URL_QUERY["mode"] === 'debug'){
        Common.DEBUG = true
      }

      // ユーザーエージェント情報を取得、bodyにもクラス名として付与する
      Common.user_agent = Common.setUserAgent()

      // PC/SP のレイアウトモード判定
      if (Common.Window.width <= Common.BREAK_POINT_SP) {
        Common.LAYOUT_MODE = 'sp'
      }
      else {
        if (Common.user_agent.Mobile) {
          Common.LAYOUT_MODE = 'sp'
        } else {
          Common.LAYOUT_MODE = 'pc'
        }

      }

      Common.current_layout_mode = Common.LAYOUT_MODE
      Common.initPreLoader()

    },
    setPage: function(page){

      this.Page = page
      page.Common = this
      window.Common = null

    },

    initPreLoader: function () {
      Common.trace('Common -> initPreLoader()')

      this.img_len = $('img').length

      Common.timeout_timer = setTimeout(function () {
        Common.hidePreLoader()
      }, Common.timeout_limit)

      if (this.img_len > 0) this.setPreLoader()
      else this.hidePreLoader()
    },

    setPreLoader: function () {
      var _this = this

      Common.trace('PRELOADER', 'initLoad')

      for (var i = 0; i < this.img_len; i++) {
        var _src = $('img:eq(' + i + ')').attr('src')
        _this.img_src_ary.push(_src)
        // Common.trace(_src);
      }

      $(document).smartpreload({
        images: _this.img_src_ary,
        oneachimageload: function (src) {
          _this.loaded_img_len++
          Common.onLoadUpdate()
        },
        onloadall: function () {
          Common.trace('PRELOADER :: All item is loaded.')
          Common.hidePreLoader()
        }
      })
    },

    onLoadUpdate: function () {
      var _this = this

      Common.trace('PRELOADER :: ' + _this.loaded_img_len + '/' + _this.img_src_ary.length + ' Loaded')
      // Common.trace('PRELOADER :: ' + _per + '% Loaded');

      // if (_this.loaded_img_len === _this.img_src_ary.length) {
      //   Common.trace('PRELOADER :: All item is loaded.')
      // }

    },

    /* -----------------------------------------------
     * PRE LOADER ANIMATION
     * ----------------------------------------------- */

    showPreLoader: function () {
      Common.trace('Common -> showPreloader')
      Common.initPreLoader()
    },

    hidePreLoader: function () {
      Common.trace('Common -> hidePreLoader')
      this.init()
    },
    /* -----------------------------------------------
     * 初期化
     * ----------------------------------------------- */
    reload_count: 0,
    reload_count_max: 2,
    isInitPage: false,
    init: function () {
      Common.trace('Common -> init()')

      clearTimeout(Common.timeout_timer)
      Common.timeout_timer = null

      if (!Common.Page && this.reload_count < this.reload_count_max) {
        this.reload_count++
        setTimeout(Common.init, 500)
        return
      }

      if(this.isInitPage){
        return
      }
      this.isInitPage = true

      Common.trace(Common.Page)
      if (Common.Page) {
        setTimeout(function () {
          Common.Page.setupOnce()
        }, 30)
      }

      // イントロが用意されている、かつトップページの場合は終了
      // if (Common.is_intro_play) return

      // pageFadeIn()

    },

    /* -----------------------------------------------
     * URLクエリの取得
     * ----------------------------------------------- */
    getURLQuery: function () {
      var url = location.href
      var parameters = url.split('?')
      if (parameters.length < 2) return false
      var params = parameters[1].split('&')
      var paramsArray = []
      for (i = 0; i < params.length; i++) {
        var neet = params[i].split('=')
        paramsArray.push(neet[0])
        paramsArray[neet[0]] = neet[1]
      }
      // var categoryKey = paramsArray[opt_key];
      return paramsArray
    },

    /* -----------------------------------------------
     * ユーザーエージェントの取得
     * bodyタグにエージェント名をクラスとして付与する
     * ----------------------------------------------- */
    setUserAgent: function () {
      Common.trace('Common -> setUserAgent()')

      var ua = window.navigator.userAgent.toLowerCase()
      var ver = window.navigator.appVersion.toLowerCase()
      var name = 'unknown'

      if (window.navigator.platform.indexOf('Win') != -1) {
        Common.is_windows = true
      }
      else if (ua.match(/Mac|PPC/)) {
        Common.is_mac = true
      }

      if (ua.indexOf('msie') != -1) {
        Common.$body.addClass('ie')
        Common.is_ie = true
        if (ver.indexOf('msie 10.') != -1) {
          name = 'ie10'
          Common.is_ie10 = true
        } else {
          name = 'ie'
          Common.is_ie = true
        }
      } else if (ua.indexOf('trident/7') != -1) {
        name = 'ie11'
        Common.is_ie11 = true
        Common.is_ie = true
        Common.$body.addClass('ie')
      } else if (ua.indexOf('chrome') != -1 || ua.indexOf('crios') != -1) {
        name = 'chrome'
        Common.is_chrome = true
      } else if (ua.indexOf('safari') != -1) {
        name = 'safari'
        Common.is_safari = true
      } else if (ua.indexOf('opera') != -1) {
        name = 'opera'
        Common.is_opera = true
      } else if (ua.indexOf('firefox') != -1) {
        name = 'firefox'
        Common.is_firefox = true
      }

      if (ua.indexOf('edge') != -1) {
        name = 'edge'
        Common.is_edge = true
        Common.is_ie = true
      }

      Common.$body.addClass(name)
      if (ua.indexOf('mobile') != -1) {
        Common.$body.addClass('mobile')
        Common.is_mobile = true
      }

      if (ua.indexOf('android') != -1) {
        Common.$body.addClass('android')
        Common.is_android = true
      }

      return (function (u) {

        var _is_ie = (u.indexOf('trident/7') > -1) || (u.indexOf('msie') > -1) && (u.indexOf('opera') == -1)

        return {
          Tablet: (u.indexOf('windows') != -1 && u.indexOf('touch') != -1)
            || u.indexOf('ipad') != -1
            || (u.indexOf('android') != -1 && u.indexOf('mobile') == -1)
            || (u.indexOf('firefox') != -1 && u.indexOf('tablet') != -1)
            || u.indexOf('kindle') != -1
            || u.indexOf('silk') != -1
            || u.indexOf('playbook') != -1,
          Mobile: (u.indexOf('windows') != -1 && u.indexOf('phone') != -1)
            || u.indexOf('iphone') != -1
            || u.indexOf('ipod') != -1
            || (u.indexOf('android') != -1 && u.indexOf('mobile') != -1)
            || (u.indexOf('firefox') != -1 && u.indexOf('mobile') != -1)
            || u.indexOf('blackberry') != -1,
          Android: (u.indexOf('android') != -1),
          iOS: (u.indexOf('iphone') != -1)
            || u.indexOf('ipod') != -1
            || u.indexOf('ipad') != -1,
          IE: _is_ie,
          IE_VERSION: (!_is_ie) ? -1 : parseInt(u.match(/((msie|MSIE)\s|rv:)([\d\.]+)/)[3]),
          Chrome: (u.indexOf('chrome') > -1) && (u.indexOf('edge') == -1),
          Firefox: (u.indexOf('firefox') > -1),
          Safari: (u.indexOf('safari') > -1) && (u.indexOf('chrome') == -1),
          Opera: (u.indexOf('opera') > -1),
          Edge: (u.indexOf('edge') > -1)
        }
      })(ua)

    },

    trace: function (args) {
      if (!Common.DEBUG) return
      // if (jQuery.browser.msie) {return};
      console.log('[trace]' + args)
    }

  }

  Common.setupOnce()
  window.Common = Common

})(jQuery, window)

/* -----------------------------------------------
 * オートスクロール
 * ----------------------------------------------- */
function autoScroll (opt_hash, opt_delay, opt_duration, opt_easing) {

  var target_pos
  var $html = $('html,body')

  if (opt_hash == '#') {
    return
  }

  target_pos = $(opt_hash).offset().top
  // target_pos-= parseInt($menu.height()) + 10;

  var target = $(opt_hash === '#' || opt_hash === '' ? 'html' : opt_hash)
  target.velocity('scroll', { duration: opt_duration, easing: opt_easing, offset: 0 })

}



